import http from "./support";
export default {
	getjoindata() {
		return http.get("getjoindata");
	},
	joinmagee(data) {
		const temp = http.encrypt(data);
		return http.post("newjoinmagee", {
			data: temp,
		});
	},
	checkemail(data) {
		return http.post("checkemail", {
			email: data.account,
			phone: data.phone,
		});
	},
	recheckmail(data) {
		return http.post("recheckmail", {
			email: data,
		});
	},
	getmemberinfo() {
		return http.get("getmemberinfo");
	},
	updatemember(data) {
		return http.post("updatemember", data);
	},
	chpwd(source) {
		const data = http.encrypt(source);
		return http.post("chpwd", {
			data: data,
		});
	},
	getOrderPost(search) {
		// const data=http.encrypt(source)
		return http.post("getOrderPost", search);
	},
	getPaySource() {
		return http.get("getPaySource");
	},
	chremarkto(order, remarkto, source) {
		const data = http.encrypt({
			order: order,
			remarkto: remarkto,
			source: source,
		});
		return http.post("chremarkto", {
			data: data,
		});
	},
	getClassdata() {
		return http.get("getClassdata");
	},
	reportmessage(type, infor) {
		return http.post("newreportmessage", {
			type: type,
			infor: infor,
		});
	},
	getresetaccount(email) {
		return http.get("getresetaccount", {
			email: email,
		});
	},
	getRefunddata(refundUid) {
		return http.get("getRefunddata", {
			uid: refundUid,
		});
	},
	saveRefunddata(data, uid) {
		return http.post("saveRefunddata", {
			data: data,
			uid: uid,
		});
	},
	getVoucherList(search, page = 0, pagenumber = null) {
		return http.post("getOwnVoucher", { search: search, page: page, pagenumber: pagenumber });
	},
};
