import http from "./support";
export default {
	SearchProList(method, uid) {
		return http.get("getProLis/" + method, { uid: uid });
	},
	getMenulist() {
		return http.get("getMenuList");
	},
	getClassList() {
		return http.get("getClassList");
	},
	getProInfo(data) {
		return http.post("newgetpromore", data);
	},
	getCart() {
		return http.post("newgetCart", {
			agent: navigator.appVersion,
		});
	},
	insertCart(data, thpro, page) {
		return http.post("newSetCart", {
			pro: data,
			agent: navigator.appVersion,
			thpro: thpro,
			page: page,
		});
	},
	getCartPro(data) {
		return http.post(
			"getCartPro",
			{
				proid: data,
			},
			{},
			"getCartPro"
		);
	},
	deleteCart(kid, pro, page) {
		return http.post("newdeleteCart", {
			agent: navigator.appVersion,
			kid: kid,
			pro: pro,
			page: page,
		});
	},
	updateCart(data) {
		return http.post("newupdateCart", {
			agent: navigator.appVersion,
			pro: data,
		});
	},
	submitorder(data) {
		data.belongcompany = process.env.VUE_APP_COMPANY;
		return http.post("newSubmitOrder", data, {
			company: process.env.VUE_APP_COMPANY,
		});
	},
	getOrderInfo(uid) {
		return http.get("getorderInfo/" + uid);
	},
	buyFreeListenPro(pro) {
		return http.get("buyFreeListenPro?pro=" + pro);
	},
	getFreeListenPro(pro) {
		return http.get("getFreeListenPro?pro=" + pro);
	},
	sendPayment(uid, data) {
		return http.post("sendPayment/" + uid, data);
	},
	getPayInfo(uid) {
		return http.post("getPayInfo/" + uid);
	},
};
