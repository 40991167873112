import pro from "@/service/Pro";
import { event } from "vue-analytics";
import { createApp } from "vue";
import Vue from "vue";
import App from "@/App.vue";
const app = createApp(App);
// state
// feemoney , showdelivery

let state = {
	Cart: [],
	CartInfo: {
    cargoInfo:{}
  },
	Order: {
		uid: "",
		ischeck: false,
		listpro: {},
		payInfo: {},
		select: {
			method: {},
			delivery: {
				remark: "",
			},
			cargonMethod: "",
			deliverymoney: 0,
			methodmoney: 0,
			shownext: false,
			ordermember: {
				name: "",
				phone: "",
				homephone: "",
				email: "",
				areacode: "",
				location: "",
			},
			recipientmember: {
				name: "",
				phone: "",
				homephone: "",
				areacode: "",
				location: "",
				remark: "",
			},
			invovice: {
				title: "",
				code: "",
				areacode: "",
				location: "",
			},
		},
		deliverylist: [
			{
				name: "旅遊產品",
				// noEleName: "方案不含書",
				// otherName: "開課取貨",
				value: "tour",
				icon: "",
				canuse: true,
				element: "all",
				hide: false,
				pstext: "",
				psshow: false,
				color: "warning",
				NCD: [false, true],
			},
			{
				name: "來店自取",
				noEleName: "方案不含書",
				otherName: "開課日領取",
				value: "LocalGet",
				icon: "",
				canuse: true,
				element: "all",
				hide: false,
				pstext: "",
				psshow: false,
				color: "warning",
				NCD: [false, true],
			},
			{
				iconimage: require("@/assets/posticon.png"),
				name: "宅配到府",
				value: "PostOff",
				show: "PostOff",
				icon: "truck",
				element: true,
				canuse: true,
				hide: false,
				pstext: "",
				psshow: false,
				color: "success",
				NCD: [false, true],
			},
			{
				iconimage: require("@/assets/7-11.jpeg"),
				name: "超商取貨",
				value: "shop",
				show: "shop",
				element: true,
				icon: "",
				canuse: true,
				hide: false,
				pstext: "",
				psshow: false,
				color: "danger",
				NCD: [false, true],
				remark: "",
				money: 60,
				Cashdelivery: 0,
			},
		],
		Paylist: [
      {
				name: "報名",
				value: "Signup",
				show: ["LocalGet"],
				icon: "money-bill-alt",
				NCD: [false, true],
        hide:true
			},
			{
				name: "現金",
				value: "LocalPay",
				show: ["LocalGet"],
				icon: "money-bill-alt",
				NCD: [false, true],
			},
			{
				name: "ATM轉帳/匯款",
				value: "ATM",
				show: ["LocalGet", "PostOff", "shop"],
				icon: "money-check-alt",
				NCD: [false, true],
			},
			{
				name: "貨到付款",
				value: "SendAfterPay",
				show: ["shop"],
				icon: "",
				fee: true,
				NCD: [false],
			},
			{
				name: "信用卡刷卡",
				value: "CreditCard",
				show: ["tour"],
				icon: "money-check-alt",
				NCD: [false, true],
			},
		],
	},
	ProList: {
		list: [],
		menu: [],
		method: "",
	},
	ProInfo: {
		crumb: [
			{
				text: "書籍",
				to: {
					name: "ProductList",
					params: {
						type: "class",
					},
				},
			},
			{
				text: "",
				active: true,
			},
		],
		ProMenu: [],

		Info: {
			information: {
				proRight: [],
			},
			otherPro: [],
			ProPrice: [],
			SelectPro: {},
			SelectAdd: [],
			pro_image: [],
			ProPriceKey: {},
		},
	},
};

// getters
const getters = {
	getcartCount(state) {
		return state.Cart.length;
	},
	NCD(state) {
		const data = state.CartInfo;
		return !(
			(data.cartlist &&
				_.filter(data.cartlist, {
					NCD: 1,
				}).length == 0) ||
			(data.addpro &&
				_.filter(data.addpro, {
					NCD: 1,
				}).length == 0)
		);
	},
	tour() {
		const data = state.CartInfo;
		return data.TourCount > 0 && !data.element && data.notCVS == 0;
	},
	CartPayMethod(state, getters) {
		const NCD = getters.NCD;
		const istour = getters.tour;
		const isship = state.CartInfo.element == true;
		const reship = _.map(
			_.filter(state.Order.deliverylist, (item) => {
				if (istour) {
					return item.value == "tour";
				}
				return item.value != "tour" && item.NCD.indexOf(NCD) > -1 && item.hide === false && (item.element == "all" || (isship && item.element == true));
			}),
			(item, keys) => {
				const sub = _.filter(state.Order.Paylist, (jt, jk) => {
					let ischeck=jt.show.indexOf(item.value) > -1 && jt.NCD.indexOf(NCD) > -1;

          if(ischeck && (state.CartInfo.cargoInfo.buymethodLimit||[]).length>0){
            ischeck  = state.CartInfo.cargoInfo.buymethodLimit.indexOf(jt.value)>-1;
          }else{
            ischeck = ischeck && !jt.hide;
          }
          return ischeck;

				});
				item.sub = sub;
				return item;
			}
		);
		const tship = _.cloneDeep(reship);
		if (tship.length == 1) {
			// tship[0].name = tship[0].noEleName;
		}
    console.log(tship,state.Order.deliverylist);
		return tship;
	},
	CartTotal(state) {
		let isNum = Number.isInteger(parseInt(state.Order.select.deliverymoney));
		let deliverymoney = 0;
		if (isNum) {
			deliverymoney = parseInt(state.Order.select.deliverymoney);
		}
		return _.reduce(
			state.CartInfo.cartlist,
			(sum, item) => {
				return sum + parseInt(item.price || 0) * parseInt(item.number) - (item.useVoucher ? item.useVoucher.price : 0)+ (item.kid=="addpro"&&item.useVoucher? item.useVoucher.price:0) ;
			},
			parseInt(deliverymoney) + parseInt(state.Order.select.methodmoney)
		);
	},
	CartInfoTotal(state) {
		return _.reduce(
			state.CartInfo.cartlist,
			(sum, item) => {
				return sum + parseInt(item.beRrice || item.price || 0) * parseInt(item.number);
			},
			0
		);
	},
  diiffTotal() {
    return _.reduce(
      state.CartInfo.cartlist,
      (cur, item) => {

        // return cur + (item.bestprice*item.number) -(item.adddifc)
        return cur + ((item.bestprice - item.price)*item.number) - (item.kid=="addpro"?item.useVoucher.price:0);
      },
      0
    );
  },
  voucherTotal() {
    return _.reduce(
      state.CartInfo.cartlist,
      (cur, item) => {
        return cur + (item.useVoucher ? item.useVoucher.price : 0);
      },
      0
    );
  },
	InfoWrite(state) {
		const ordermember = _.filter(state.Order.select.ordermember, (item, keys) => {
			if (item && keys == "email") {
				const test = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
				return !test.test(item);
			} else {
				if (keys == "homephone") {
					return false;
				}
				return !item;
			}
		});
		const recipientmember = _.filter(state.Order.select.recipientmember, (item, keys) => {
			if (item && keys == "email") {
				const test = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
				return test.test(item);
			} else {
				if (keys == "homephone") {
					return false;
				}
				return !(keys == "remark" || item);
			}
		});
		return {
			order: ordermember,
			recipient: recipientmember,
		};
	},
	Inext(state) {
		return (page) => {
			const isnext = page;
			let result = false;
			let message = [];
			switch (isnext) {
				case 0:
					result = !(Object.keys(state.Order.select.method).length === 0) && !(Object.keys(state.Order.select.delivery).length === 0);
					break;
				case 1:
					message = _.filter(getters.InfoWrite(state), (item, keys) => {
						return item.length > 0;
					});
					result = message.length == 0;
					if (result && state.Order.select.delivery.value == "shop") {
						result = !!state.Order.select.delivery.remark;
						message.push("請選擇收件超商");
					}
					break;
				case 2:
					result = true;
					break;
				case 3:
					break;
			}
			return {
				state: result,
				message: message,
			};
		};
	},
};

// actions
const actions = {
	refdefaultdelivery({ commit, state }) {
		commit("setSelectDeliverymoney", 0);
		commit("setSelectCargoMethod", "onecargo");
		commit("setSelectmethodmoney", 0);
		commit("setSelectMethod", {});
		commit("setSelectDelivery", {});
		// setSelectMethod setSelectDelivery
	},
	submitOrder({ commit, state }, { vm }) {
		const listpro = [];
		let totelmoney = "";

		_.each(state.CartInfo.cartlist, (iit, kl) => {
			const pricekid = iit.kid == "addpro" ? "NO" : iit["pricekid"];
			const temp = {
				id: iit["id"],
				name: iit["name"],
				number: iit["number"],
				outpot: "RMD1611160001",
				price: iit["price"],
				pricekid: pricekid,
				pro_class: iit["pro_class"],
				uid: iit["uid"],
				uidcode: iit["uidcode"],
				totel: iit["price"] * iit["number"],
				voucher: iit["useVoucher"],

			};
			if (iit["group"]) {
				temp["belong"] = iit["group"];
			}
			totelmoney = +temp.totel;
			listpro.push(temp);
		});

		const waytext = state.Order.select.delivery.name == "宅配到府" ? "郵局" : state.Order.select.delivery.name == "超商取貨" ? "超商" : "";
		const waycode = state.Order.select.delivery.name == "宅配到府" ? "D" : state.Order.select.delivery.name == "超商取貨" ? "C" : "";
		const data = {
			infor: state.Order.select.ordermember,
			receipt: state.Order.select.recipientmember,
			company: state.Order.select.invovice,
			listpro: listpro,
			delivery: {
				getmethod: {
					name: "運費-" + state.Order.select.delivery.name + waycode,
					type: state.Order.select.delivery.name != "方案不含書" ? state.Order.select.delivery.value : "null",
					remark: state.Order.select.delivery.remark ? state.Order.select.delivery.remark.join() : "",
					money: state.Order.select.deliverymoney,
				},
				pay: {
					name: state.Order.select.method.value == "SendAfterPay" ? `${waytext}代收處理費${waycode}` : state.Order.select.method.name,
					value: state.Order.select.method.value,
					money: state.Order.select.methodmoney,
				},
				money: parseInt(state.Order.select.deliverymoney) + parseInt(state.Order.select.methodmoney),
				multi: [],
			},
		};
		if (state.Order.select.cargonMethod == "multicargo") {
			data.delivery.multi = state.CartInfo.multidivary.mulit;
		}

		const result = pro.submitorder(data);
		result.then((data) => {
			if (data.data.state) {
				console.log("ecommerce Start");

				// 'id': 'T12345',
				// 'affiliation': 'Google Store - Online',
				// 'revenue': '37.39',
				// 'tax': '2.85',
				// 'shipping': '5.34',
				// Vue.$ga.ecommerce.addTransaction({
				//   id: data.data.result.order_uid,
				//   affiliation: state.Order.select.ordermember.name,
				//   revenue: totelmoney + state.Order.select.deliverymoney,
				//   shipping: state.Order.select.deliverymoney,
				//   tax: 0,
				//   currency: 'TWD'
				// });

				_.each(listpro, (item, keys) => {
					vm.$ga.ecommerce.setAction("addProduct", {
						id: item.id,
						name: item.name,
						category: item.pro_class,
						brand: "Magee",
						variant: "black",
						price: item.number,
						quantity: 1,
					});
					vm.$ga.ecommerce.setAction("purchase", {
						id: data.data.result.order_uid,
						affiliation: state.Order.select.ordermember.name,
						revenue: totelmoney + state.Order.select.deliverymoney,
						shipping: state.Order.select.deliverymoney,
						tax: 0,
						currency: "TWD",
					});
					// Vue.$ga.ecommerce.addItem({
					//   'id': item.id,
					//   'name': item.name,
					//   'sku': item.uid,
					//   'category': item.pro_class,
					//   'price': item.price,
					//   'quantity': item.number,
					//   currency: 'TWD'
					// })
				});
				// Vue.$ga.ecommerce.send();
				// Vue.$ga.query('ecommerce:send');
				// console.log("ecommerce End");
			}
		});
		return result;
	},
	async getOrderInfo({ commit }, data) {
		const orderinfo = await pro.getOrderInfo(data.uid);
		// console.log(orderinfo);
		const listpro = orderinfo.data;
		const payinfo = data.data.result.lastpay;
		// console.log(payinfo, data.data.result, 391);
		commit("setOrderSuccess", {
			uid: data.uid,
			check: true,
			listpro: listpro,
			payInfo: payinfo,
		});
	},
	Search({ commit }, data) {
		const method = data.method;
		const result = pro.SearchProList(method, data.uid);
		result.then((data) => {
			//   console.log(data,data.data.result);
			commit("setProList", {
				data: data.data.result,
				method: method,
			});
		});
		return result;
	},
	getMenulist({ commit }) {
		const result = pro.getMenulist();
		result.then((data) => {
			commit("setMenulist", data.data.result);
		});
		return result;
	},
	getClassList({ commit }) {
		const result = pro.getClassList();
		result.then((data) => {
			commit("setClassList", data.data.result);
		});
		return result;
	},
	getProInfo({ commit }, data) {
		commit("clearProInfo");
		// console.log(data);
		const result = pro.getProInfo(data);
		const method = data.method;
		result.then((data) => {
			// console.log(data);
			let nowclass = data.data.result.ProName.outclass;
			pro.getClassList().then((data) => {
				const a = _.filter(data.data.result.class, (item) => {
					if (item.uid == nowclass) {
						return item.name;
					}
				});
				const b = _.filter(data.data.result.book, (item) => {
					if (item.uid == nowclass) {
						return item.name;
					}
				});
				commit("setCrumb", {
					method: method,
					text: a.length > 0 ? a[0].name : b[0].name,
				});
			});
			commit("setProInfo", data.data.result);
			// console.log(data.data.result.ProName.outclass)
			commit(
				"Public/setHeaderinfo",
				{
					title: data.data.result.ProName.name,
					meta: {
						keywords: data.data.result.ProName.KeyWords,
						description: data.data.result.ProName.Description,
					},
				},
				{
					root: true,
				}
			);
		});
		return result;
	},
	getCart({ commit }) {
		const result = pro.getCart();
		result.then((data) => {
			commit("setCart", data.data.result);
		});
	},
	async getCartPro({ commit, state }) {
		const result = await pro.getCartPro(state.Cart);
		commit("setshownext", false);
		// result.then((data) => {
		// commit("setCart", data.data.result);
		commit("setCartInfo", result.data);
		commit("setshownext", true);

		// });
		// });
		return result;
	},
	deleteCart({ state, commit, dispatch }, data) {
		const method = data.methodpage || "pro";
		const vm = data.vm;
		if (!data.pro) {
			return;
		}
		const result = pro.deleteCart(data.kid, data.pro, method);
		// console.log(data.kid,data.pro,method);
		const thdata = data;
		result.then((data) => {
			dispatch(
				"Public/showToast",
				{
					title: "移除完成",
					method: "warning",
					toaster: "b-toaster-top-center b-toast-v-center",
					time: 800,
					text: "已把商品移除購物車",
					vm: vm,
				},
				{
					root: true,
				}
			);
			vm.$ga.ecommerce.setAction("remove", {
				id: thdata.pro,
				// revenue:
			});
			commit("setCart", data.data.result.cart);
			if (method == "cart") {
				commit("setCartInfo", data.data.result.cartinfo);
			}
		});
		return result;
	},
	changerProNumber({ state, commit, dispatch }, data) {
		const method = data.methodpage || "pro";
		const result = pro.updateCart(data);

		result.then((data) => {
			// dispatch("Public/showToast", {
			//   title: "數量變更成功",
			//   method: 'success',
			//   toaster: 'b-toaster-top-center b-toast-v-center',
			//   time: 800,
			//   text: "商品已變更購買數量"
			// }, {
			//   root: true
			// })
			commit("setCart", data.data.result.cart);
			if (method == "cart") {
				commit("setCartInfo", data.data.result.cartinfo);
			}
		});
		return result;
	},
	insertCart({ state, commit, dispatch }, data) {
		let addpro = [];
		const vm = data.vm;
		const thpro = _.map(state.ProInfo.Info.ProPrice, (item, keys) => {
			const temp = {
				id: item.id,
				method: "main",
			};
			_.each(item.addpro, (iit) => {
				addpro.push({
					id: iit.id,
					method: "addpro",
				});
			});
			return temp;
		});
		_.each(addpro, (item) => {
			thpro.push(item);
		});
		const method = data.methodpage || "pro";
		const thdata = data.data;
		const result = pro.insertCart(data.data, thpro, method);

		result.then((data) => {
			// console.log(vm)
			_.each(thdata, (item, keys) => {
				// $ga 無法重新定義 TypeError: Cannot read properties of undefined (reading '$ga')
				// vm.$ga.ecommerce.setAction("add", {
				// 	id: item.id,
				// 	list: method + "/" + item.kid + "/" + item.number,
				// });
			});
			commit("setCart", data.data.result.cart);
			if (method == "cart") {
				commit("setCartInfo", data.data.result.cartinfo);
			}
		});
		return result;
	},
	async buyFreeListenPro({}, prolist) {
		const result = await pro.buyFreeListenPro(prolist).then((data) => data.data);
		return result;
	},
	async getFreeListenPro({}, prolist) {
		const result = await pro.getFreeListenPro(prolist).then((data) => data.data.result.getpro);
		return result;
	},
	async getPayInfo({ commit }, { payuid }) {
		const result = await pro.getPayInfo(payuid).then((data) => data.data.result);
		return result;
	},
	async sendPayment({ commit }, { uid, data }) {
		const result = await pro.sendPayment(uid, data);
		return result;
	},
};

// mutations
const mutations = {
	setProList(state, data) {
		state.ProList.list = data.data.ProClass;
		state.ProList.method = data.method;
	},
	setMenulist(state, data) {
		state.ProList.menu = data;
	},
	setClasslist(state, data) {
		state.ProInfo.class = data;
	},
	clearProInfo(state, data) {
		state.ProInfo.Info.information = {
			proRight: [],
		};
		state.ProInfo.Info.ProPrice = [];
		state.ProInfo.Info.SelectPro = {};
		state.ProInfo.Info.SelectAdd = [];
		state.ProInfo.Info.pro_image = [];
		state.ProInfo.Info.otherPro = [];
	},
	setProInfo(state, data) {
		state.ProInfo.Info.information = data.ProName;
		state.ProInfo.Info.ProPrice = data.ProCaseList;
		state.ProInfo.Info.ProPriceKey = _.keyBy(data.ProCaseList, "uid");
		state.ProInfo.Info.otherPro = data.otherPro;
		state.ProInfo.Info.pro_image = data.pro_image;
		if (data.selectPro.main) {
			state.ProInfo.Info.SelectPro = data.selectPro.main;
			state.ProInfo.Info.SelectAdd = data.selectPro.addpro;
		} else {
			if (data.ProCaseList.length == 1) {
				state.ProInfo.Info.SelectPro = data.ProCaseList[0];
			} else {
				state.ProInfo.Info.SelectPro = {};
			}
		}
	},
	setCrumb(state, data) {
		const tagname = {
			book: "書籍",
			class: "課程",
		};
		state.ProInfo.crumb[0].to.params.type = data.method;
		state.ProInfo.crumb[0].text = tagname[data.method];
		state.ProInfo.crumb[1].text = data.text;
	},
	setSelectPro(state, data) {
		state.ProInfo.Info.SelectPro = data;
	},
	setSelectAdd(state, data) {
		state.ProInfo.Info.SelectAdd = data;
	},
	setCart(state, data) {
		state.Cart = _.map(data, (item, keys) => {
			return item;
		});
	},
	setSelectDelivery(state, data) {
		state.Order.select.delivery = data;
		// state.Order.select.method = data.sub[0];
		// state.Order.select.deliverymoney = 0;
	},
	setSelectdeliveryRemark(state, data) {
		state.Order.select.delivery.remark = data;
	},
	setSelectMethod(state, data) {
		state.Order.select.method = data;
	},

	setSelectDeliverymoney(state, data) {
		state.Order.select.deliverymoney = data;
	},
	setSelectCargoMethod(state, data) {
		state.Order.select.cargonMethod = data;
	},
	setshownext(state, data) {
		state.Order.select.shownext = data;
	},
	setSelectmethodmoney(state, data) {
		state.Order.select.methodmoney = data;
	},
	setCartInfo(state, data) {
		state.CartInfo = data;
		const a = _.findIndex(state.Order.deliverylist, {
			value: "shop",
		});
		if (a > -1) {
			// console.log( data );
			// console.log( data.shop );
			state.Order.deliverylist[a].money = data.shop.money;
			state.Order.deliverylist[a].Cashdelivery = data.shop.afterdivary;
			state.Order.deliverylist[a].canuse = data.shop.state;
			state.Order.deliverylist[a].hide = !data.shop.state;
		}
	},
	setOrdermember(state, data) {
		state.Order.select.ordermember = data;
	},
	setRecipientmember(state, data) {
		state.Order.select.recipientmember = data;
	},
	setInvovice(state, data) {
		state.Order.select.setInvovice = data;
	},
	setOrderSuccess(state, { uid, check, listpro, payInfo }) {
		state.Order.uid = uid;
		state.Order.ischeck = check;
		state.Order.listpro = listpro;
		state.Order.payInfo = payInfo;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
